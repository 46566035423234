import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
          <path d="M0 5120 l0 -5120 5120 0 5120 0 0 5120 0 5120 -5120 0 -5120 0 0
-5120z m5501 3485 c154 -17 252 -34 425 -75 134 -32 362 -105 439 -140 28 -13
65 -24 82 -24 18 -1 33 -6 33 -12 0 -10 44 -33 107 -54 17 -6 34 -18 37 -26 3
-9 10 -13 16 -9 13 8 130 -53 127 -67 -1 -6 15 -20 36 -30 20 -10 37 -22 37
-28 0 -5 6 -7 13 -4 15 5 69 -40 59 -50 -3 -4 1 -4 11 0 10 4 17 1 17 -6 0
-16 -60 -5 -103 19 -18 10 -81 35 -142 56 -314 110 -369 126 -354 109 6 -8 19
-14 28 -14 14 0 14 -2 1 -10 -12 -8 -12 -10 5 -10 20 -1 20 -1 0 -15 -19 -15
-19 -16 -1 -33 15 -13 18 -14 14 -2 -7 18 18 45 27 30 3 -6 1 -10 -4 -10 -6 0
-11 -9 -11 -19 0 -23 42 -36 69 -21 24 12 60 2 39 -11 -18 -12 -31 -11 -23 1
3 6 -1 7 -10 3 -13 -4 -14 -8 -4 -14 20 -12 4 -22 -18 -10 -125 69 -618 211
-838 241 -44 6 -136 13 -203 17 -94 5 -125 10 -129 20 -5 12 -7 12 -14 1 -7
-10 -9 -8 -9 6 0 11 -3 16 -7 12 -5 -4 -13 -206 -19 -449 -10 -425 -10 -443 8
-459 21 -19 24 -32 4 -25 -7 3 -17 2 -22 -3 -5 -4 -66 -11 -135 -14 -148 -8
-139 -16 -139 119 0 90 8 105 56 105 30 0 32 2 39 48 4 26 6 227 5 447 -2 220
-2 405 -1 412 0 6 9 14 19 17 28 9 288 -2 433 -19z m-1131 -109 c0 -13 7 -56
16 -96 16 -68 16 -74 1 -85 -9 -7 -76 -27 -149 -45 -221 -54 -325 -92 -543
-195 -264 -125 -522 -290 -740 -475 -76 -64 -288 -283 -344 -355 -34 -44 -68
-87 -74 -95 -72 -90 -127 -162 -147 -192 -14 -21 -28 -38 -32 -38 -5 0 -8 -5
-8 -11 0 -6 -12 -28 -27 -50 -30 -41 -56 -49 -96 -28 -47 25 -117 73 -117 81
0 27 284 433 316 451 25 15 15 32 -75 121 l-88 88 -63 -71 c-35 -40 -68 -70
-74 -68 -25 8 -14 39 33 89 28 29 51 56 51 61 0 14 36 6 78 -18 49 -28 54 -20
13 24 -43 46 -64 41 -142 -39 -38 -38 -69 -73 -69 -77 0 -5 -4 -14 -9 -22 -7
-11 -21 -6 -67 25 -33 20 -67 45 -77 53 -11 9 -24 16 -30 17 -33 3 -37 5 -37
18 0 8 83 99 184 202 152 156 186 187 197 176 11 -11 12 -14 1 -21 -10 -6 29
-53 138 -169 155 -166 184 -192 193 -169 4 9 8 9 15 -2 11 -15 8 -18 183 140
123 110 136 119 169 113 38 -8 46 10 20 52 l-21 36 43 48 c24 26 54 53 68 61
33 19 60 46 53 53 -4 3 4 6 16 6 14 0 25 9 31 25 5 14 16 25 24 25 7 0 19 6
25 14 6 7 25 16 41 20 17 3 30 11 30 17 0 6 -7 8 -16 5 -14 -5 -15 -4 -5 8 7
8 19 12 26 10 8 -3 24 1 37 10 l22 16 -27 0 c-34 0 -90 -27 -82 -40 3 -5 -6
-12 -20 -16 -14 -3 -25 -10 -25 -15 0 -5 -5 -9 -11 -9 -5 0 -7 5 -3 11 4 8 0
8 -15 0 -11 -7 -21 -16 -21 -20 0 -5 -10 -11 -22 -15 -24 -6 -145 -88 -187
-126 -18 -16 -26 -19 -35 -10 -8 9 -3 18 19 36 41 34 203 141 300 199 146 86
448 225 491 225 21 0 54 -28 54 -46 0 -7 7 -19 15 -28 13 -13 24 -11 98 15
112 41 413 118 460 119 32 0 37 -3 37 -24z m2080 -328 c0 -4 -4 -8 -9 -8 -6 0
-12 4 -15 8 -3 5 1 9 9 9 8 0 15 -4 15 -9z m100 -32 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-40 -16 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3420 -24 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m4910 -163 c0
-5 -17 -19 -38 -33 -20 -14 -103 -90 -184 -169 -81 -78 -152 -141 -158 -139
-7 2 55 68 138 146 178 170 242 222 242 195z m-770 -55 c0 -13 -23 -5 -28 10
-2 7 2 10 12 6 9 -3 16 -11 16 -16z m-1160 -398 c102 -39 274 -120 267 -126
-2 -2 -42 14 -88 35 -85 40 -408 151 -439 151 -22 0 -39 -20 -67 -79 -16 -33
-22 -40 -22 -24 -1 27 37 125 51 134 11 7 167 -40 298 -91z m-1626 74 c3 -9 6
-32 5 -52 l-1 -37 -10 33 c-9 25 -16 32 -37 32 -46 0 -339 -108 -451 -166 -36
-19 -70 -34 -74 -34 -4 0 -34 -18 -67 -40 -33 -22 -63 -40 -66 -40 -5 0 -86
-61 -135 -102 -16 -13 -34 -28 -40 -33 -96 -77 -161 -138 -203 -190 -16 -20
-41 -50 -53 -66 -13 -16 -28 -27 -33 -24 -5 4 -6 0 -3 -8 3 -8 -9 -25 -30 -41
-20 -15 -39 -38 -42 -51 -3 -14 -11 -22 -17 -18 -5 3 -7 1 -4 -5 10 -16 -4
-44 -19 -38 -9 3 -11 -1 -7 -12 3 -9 3 -14 -2 -10 -10 9 -45 -29 -45 -49 0 -7
-4 -13 -9 -13 -4 0 -12 -14 -17 -32 -7 -23 -17 -34 -37 -39 -16 -4 -27 -5 -25
-1 2 4 26 42 53 85 296 464 670 757 1180 922 155 51 179 54 189 29z m-12 -121
c10 -9 18 -22 18 -30 0 -7 9 -22 20 -33 24 -24 17 -45 -18 -58 -15 -5 -81 -28
-147 -52 -210 -73 -397 -172 -570 -300 -179 -133 -310 -273 -450 -482 -32 -49
-62 -88 -66 -88 -8 0 -66 36 -101 62 -16 12 -10 24 63 133 145 214 269 349
443 479 226 169 390 255 666 347 63 21 117 39 119 39 3 0 13 -7 23 -17z m1494
-38 c338 -118 587 -267 801 -481 82 -81 101 -105 96 -123 -4 -17 -1 -21 20
-21 25 0 45 -16 34 -27 -9 -9 -67 8 -67 20 0 17 -32 47 -51 47 -10 0 -23 8
-28 18 -6 9 -11 13 -11 9 0 -5 -7 -1 -16 7 -8 9 -12 20 -9 26 3 5 1 10 -5 10
-6 0 -37 19 -68 42 -224 166 -361 248 -512 305 -89 34 -335 94 -355 87 -8 -3
-22 -3 -30 0 -14 5 -14 8 -1 28 8 13 17 42 21 66 4 23 11 42 15 42 5 0 80 -25
166 -55z m-4266 -572 c14 -7 66 -28 115 -47 234 -90 413 -162 570 -231 102
-45 158 -65 163 -61 3 3 10 -1 16 -9 6 -9 26 -17 44 -20 18 -2 39 -9 47 -15 7
-6 17 -10 21 -8 10 3 103 -31 120 -44 6 -4 26 -3 44 2 36 10 48 2 70 -51 10
-23 7 -35 -15 -79 -28 -55 -66 -100 -85 -100 -6 0 -14 9 -18 21 -6 14 -29 28
-74 44 -69 24 -218 85 -403 165 -60 26 -132 55 -158 64 -27 9 -61 22 -75 30
-15 7 -49 17 -77 21 -27 4 -54 10 -60 15 -16 11 -108 41 -113 35 -6 -6 98 -55
116 -55 6 0 12 -4 12 -10 0 -5 7 -10 15 -10 19 0 19 -9 1 -29 -7 -9 -33 -61
-56 -116 -23 -55 -62 -143 -85 -195 -43 -94 -61 -145 -110 -311 -14 -48 -37
-102 -50 -120 -13 -19 -29 -45 -35 -59 -18 -39 -31 -6 -29 70 1 36 1 85 0 109
-1 45 -1 45 -20 24 -11 -11 -22 -33 -25 -49 -3 -16 -10 -42 -15 -59 l-11 -30
6 35 c3 19 7 71 10 115 6 98 36 246 75 373 33 107 32 102 21 102 -10 0 -42
-65 -42 -85 0 -18 -40 -88 -57 -98 -7 -4 -13 -17 -13 -27 0 -11 -13 -46 -30
-78 -16 -33 -30 -66 -31 -73 0 -8 -9 -27 -20 -44 -10 -16 -26 -47 -35 -68 -21
-48 -59 -60 -86 -28 -13 17 -17 39 -17 79 2 81 21 185 31 170 5 -8 6 -2 2 14
-3 15 -2 33 2 40 5 7 9 19 11 27 12 83 88 297 154 438 55 118 133 238 148 230
3 -1 17 -8 31 -14z m5879 -415 c0 -7 -15 -26 -33 -43 l-32 -30 24 -68 c25 -69
25 -84 -2 -74 -20 8 -43 45 -66 108 -23 61 -18 72 45 100 47 22 65 24 64 7z
m-169 -67 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4
10 -9z m-3767 -547 c137 -31 228 -97 285 -209 37 -73 43 -117 21 -146 -15 -19
-21 -21 -28 -10 -7 11 -10 9 -16 -8 -3 -11 -12 -21 -18 -21 -7 0 -23 -8 -36
-17 -23 -16 -23 -17 -5 -24 18 -7 18 -8 0 -8 -12 -1 -16 -6 -12 -16 12 -33
-11 -11 -44 40 -19 30 -38 55 -42 55 -5 0 -8 7 -8 15 0 8 -14 22 -31 31 -25
12 -29 18 -18 25 10 6 8 9 -11 10 -14 1 -28 1 -32 0 -5 0 -8 3 -8 9 0 5 -3 9
-7 8 -5 0 -15 2 -23 5 -35 14 -126 25 -142 16 -9 -5 -35 -9 -57 -9 -53 0 -251
-102 -251 -130 0 -8 -11 -20 -25 -27 -22 -10 -25 -9 -25 7 0 11 8 23 17 29 24
13 9 25 -32 25 -19 0 -35 4 -35 9 0 4 -5 5 -10 2 -6 -4 -10 -3 -9 2 4 20 -2
34 -13 32 -33 -4 -38 2 -35 34 5 46 16 64 29 46 8 -11 9 -11 5 1 -2 8 3 24 12
35 16 20 16 20 -5 2 -11 -9 -24 -14 -29 -10 -4 5 -5 3 -2 -3 4 -6 -10 -31 -31
-55 -20 -24 -41 -50 -47 -57 -5 -7 -15 -19 -22 -26 -7 -8 -9 -16 -6 -20 4 -3
2 -6 -4 -6 -12 0 -36 -50 -28 -58 3 -3 -2 -13 -10 -22 -8 -9 -21 -43 -29 -76
-17 -69 -22 -28 -6 46 32 144 131 289 260 379 136 94 380 136 563 95z m1158
-59 c26 -73 22 -89 -6 -25 -27 61 -33 56 -11 -8 22 -61 47 -96 58 -80 5 8 6 0
3 -20 -4 -22 2 -46 17 -75 35 -68 38 -76 38 -97 0 -11 4 -20 9 -20 5 0 12 -12
16 -27 3 -15 11 -30 15 -33 5 -3 11 -24 14 -45 3 -26 13 -45 26 -54 27 -18 68
-113 42 -97 -5 3 -8 -16 -6 -42 1 -26 -3 -52 -8 -59 -5 -7 -7 -17 -3 -23 3 -5
-1 -10 -9 -10 -9 0 -16 -9 -16 -20 0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0
11 -4 20 -10 20 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -14 -15 -19
-32 -9 -32 -10 -32 -8 12 2 36 -1 45 -17 48 -17 4 -18 0 -12 -43 3 -27 11 -54
17 -61 6 -8 8 -14 3 -14 -5 0 -2 -5 6 -11 12 -8 12 -10 1 -7 -8 2 -32 40 -54
84 -21 43 -43 86 -47 94 -25 41 -49 103 -44 111 3 5 0 8 -7 7 -6 -2 -14 3 -16
10 -3 6 0 12 7 12 6 0 8 5 5 10 -4 6 -10 8 -15 5 -15 -9 -22 33 -9 57 9 19 9
21 -1 11 -11 -10 -17 -8 -32 9 -10 12 -18 25 -18 30 0 5 -4 7 -9 4 -5 -4 -12
0 -15 6 -3 7 -3 0 0 -17 5 -24 3 -27 -6 -15 -10 12 -11 12 -4 -5 10 -28 8 -61
-6 -95 -12 -29 -35 -44 -24 -16 3 8 1 17 -5 21 -16 9 -26 -24 -12 -38 17 -17
13 -34 -9 -40 -12 -3 -20 -14 -20 -26 0 -12 -7 -24 -16 -28 -11 -4 -15 -18
-13 -52 5 -85 -1 -157 -11 -141 -10 16 -11 15 -28 -24 -10 -23 -15 -26 -27
-16 -8 6 -19 9 -24 5 -6 -3 -13 6 -17 20 -3 14 -10 25 -15 25 -5 0 -9 18 -9
40 0 33 -3 40 -20 40 -17 0 -20 7 -22 43 l-1 42 -7 -45 c-6 -37 -7 -29 -10 50
-2 67 -5 86 -10 65 -4 -16 -8 116 -9 294 0 178 -4 322 -7 318 -4 -3 -10 -52
-14 -109 l-7 -103 -2 132 -1 133 203 2 c111 2 222 3 246 3 44 0 44 0 62 -50z
m1144 40 c25 -8 32 -13 19 -14 -19 -1 -20 -7 -19 -101 0 -75 -3 -100 -12 -100
-8 0 -13 -13 -13 -34 0 -26 -5 -36 -20 -41 -16 -5 -20 -15 -20 -56 0 -27 -4
-49 -9 -49 -5 0 -14 -13 -21 -30 -15 -35 -30 -39 -30 -8 0 17 -3 19 -12 10 -7
-7 -20 -12 -29 -12 -10 0 -19 -5 -21 -10 -2 -6 -13 -2 -25 9 l-22 21 -6 -21
c-4 -12 -13 -18 -20 -15 -12 4 -12 0 -2 -21 l11 -26 9 34 c5 18 12 28 15 22 4
-6 18 -14 32 -18 14 -4 42 -14 63 -23 35 -15 38 -19 33 -46 -3 -15 -6 -37 -6
-47 0 -25 -32 -25 -50 1 -19 26 -28 25 -33 -2 -3 -17 -4 -14 -4 7 0 17 -10 46
-22 65 l-22 35 3 -30 c2 -21 -2 -30 -14 -33 -13 -3 -18 3 -18 21 0 13 -10 35
-22 48 -13 15 -19 18 -15 7 3 -9 0 -19 -9 -22 -8 -3 -14 -13 -14 -21 0 -9 -5
-25 -11 -36 -10 -20 -11 -20 -31 3 l-20 23 7 -24 c5 -16 4 -21 -4 -17 -6 4
-11 2 -11 -4 0 -7 -9 -25 -21 -41 -12 -17 -19 -39 -16 -49 4 -17 2 -18 -14 -9
-18 9 -19 8 -8 -9 13 -20 5 -57 -9 -48 -13 8 -58 -45 -66 -76 -4 -18 -11 -25
-20 -22 -8 3 -17 1 -21 -5 -3 -6 -1 -11 6 -11 7 0 9 -3 6 -6 -4 -4 -13 -1 -21
5 -8 7 -27 11 -42 9 -22 -2 -28 1 -29 17 0 11 -4 42 -9 69 l-9 48 -23 -26
c-13 -14 -24 -31 -24 -39 0 -26 -13 -27 -26 -1 -12 22 -11 30 1 55 9 16 14 30
13 31 -1 2 6 17 15 33 18 32 201 411 269 558 l41 87 181 -1 c121 0 194 -5 221
-14z m2945 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-2913 -327 c-2 -32 -3 -8 -3 52 0 61 1 87 3 58 2 -29 2 -78 0
-110z m-3011 -1 c-11 -10 -15 4 -8 28 l7 25 3 -23 c2 -13 1 -26 -2 -30z m409
1 c-27 -14 -68 -39 -90 -56 l-40 -31 25 28 c28 32 117 86 140 85 8 0 -7 -12
-35 -26z m922 -65 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-1197 -39 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m121 -22 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z
m2489 -102 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4
-15z m170 -25 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m97 -27 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m-4623 -8 c-8 -20 -36 -35 -65 -35 -18 0 -13 5 21 24 25 13 46 24 47 25 1 0 0
-6 -3 -14z m7624 -10 c7 -14 12 -32 12 -40 0 -8 5 -15 10 -15 6 0 10 -16 10
-36 0 -32 1 -34 16 -20 8 9 21 16 27 15 9 0 8 -2 0 -6 -7 -2 -13 -10 -13 -16
0 -15 -31 -47 -47 -47 -8 0 -13 12 -13 31 0 24 -4 30 -15 25 -20 -7 -45 3 -45
19 0 18 -30 27 -46 14 -11 -9 -14 -8 -14 6 0 20 73 95 93 95 8 0 19 -11 25
-25z m-7738 -6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10
-10 10 -16z m4460 -49 c-4 -8 -8 -121 -9 -250 -1 -154 -5 -236 -12 -241 -7 -4
-9 -20 -5 -40 4 -24 3 -28 -3 -14 -11 26 -1 545 10 545 13 0 11 28 -3 32 -7 3
-8 10 -4 17 11 18 35 -29 26 -49z m-1093 -12 c0 -25 -2 -32 -4 -18 -3 14 -10
28 -17 33 -8 5 -7 10 4 17 8 5 15 10 16 10 0 0 1 -19 1 -42z m1213 32 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m157 -52 c-2
-13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-4599 36 c16 -3 22 -10
18 -20 -3 -9 -1 -24 4 -34 13 -25 2 -50 -22 -50 -18 0 -108 81 -108 98 0 23
22 24 108 6z m3642 -98 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15
10 8 0 15 -2 15 -4z m10 -66 c0 -20 -5 -30 -15 -30 -10 0 -15 10 -15 30 0 20
5 30 15 30 10 0 15 -10 15 -30z m-50 5 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15
4 8 8 15 10 15 2 0 4 -7 4 -15z m-1104 -22 c29 -15 52 -28 51 -29 -1 -2 -19 1
-40 6 -35 7 -38 6 -44 -18 -6 -24 -7 -25 -14 -7 -7 19 -8 19 -8 1 -1 -11 -6
-16 -15 -13 -8 3 -17 1 -20 -6 -3 -9 -8 -8 -15 3 -9 13 -11 13 -11 -2 0 -10
-5 -18 -11 -18 -6 0 -8 -7 -5 -16 4 -12 2 -15 -9 -10 -8 3 -15 1 -15 -4 0 -9
-34 -34 -114 -84 -24 -16 -50 -20 -117 -20 -87 -1 -122 9 -163 47 -24 21 -23
21 55 1 82 -21 189 -15 244 13 56 29 123 101 137 145 14 47 38 50 114 11z
m1064 17 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m385 -40 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-410 -40 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m3612 -137 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m-17 -130 c-18 -102 -24 -85 -8 23 7 49 14 80 16 70 2 -11 -1 -53 -8 -93z
m-30 -160 c-14 -75 -94 -329 -111 -353 -9 -13 -10 -13 -5 0 48 142 90 280 103
346 21 99 31 105 13 7z m-3175 36 c4 -6 5 -12 2 -15 -7 -7 -37 7 -37 17 0 13
27 11 35 -2z m-2485 -413 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15
10 8 0 15 -2 15 -4z m5506 -83 c-36 -75 -70 -123 -88 -123 -4 0 7 19 25 43 17
23 45 71 61 105 16 34 32 60 34 57 3 -2 -12 -39 -32 -82z m-5619 22 c-16 -25
-27 -34 -42 -30 -19 4 -144 -35 -335 -105 -68 -25 -391 -134 -566 -190 -41
-13 -49 -13 -35 4 7 8 28 17 49 21 20 4 75 19 122 35 110 36 632 228 730 268
97 40 104 40 77 -3z m63 -6 c0 -10 -6 -19 -14 -19 -19 0 -30 31 -14 41 17 10
28 2 28 -22z m4884 -101 c-7 -46 -34 -89 -34 -54 0 29 22 96 32 96 4 0 5 -19
2 -42z m-177 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-4216
-64 c3 -21 13 -52 22 -69 15 -27 15 -30 1 -35 -10 -4 -31 8 -56 32 -43 41 -49
43 -78 28 -38 -20 -29 9 12 43 72 58 90 58 99 1z m3867 -9 c27 -25 32 -35 20
-35 -10 0 -18 5 -18 12 0 16 -30 -18 -60 -67 -98 -163 -163 -249 -275 -361
-175 -176 -344 -296 -560 -398 -91 -43 -260 -101 -350 -120 -53 -11 -60 -15
-58 -34 2 -12 -1 -22 -6 -22 -11 0 -43 89 -36 100 3 4 25 11 50 14 47 7 175
51 306 103 155 63 390 219 536 356 90 85 199 217 258 313 107 175 106 174 131
174 14 0 40 -15 62 -35z m1476 -59 c-15 -31 -29 -55 -31 -53 -2 3 8 30 23 61
16 30 29 54 31 52 2 -2 -8 -29 -23 -60z m-4804 -586 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m75 -40 c34 -24 229 -120 242
-120 5 0 48 -16 96 -36 48 -19 111 -42 140 -50 28 -8 68 -19 87 -25 19 -6 43
-10 53 -10 10 0 16 -6 14 -12 -3 -7 -10 -11 -16 -9 -7 1 -10 -2 -6 -8 3 -6 -1
-17 -9 -26 -9 -8 -16 -26 -16 -40 0 -15 -6 -24 -16 -24 -13 0 -14 3 -4 15 8 9
10 28 6 50 -6 30 -11 35 -35 35 -32 0 -181 46 -195 61 -6 5 -19 9 -30 9 -12 0
-84 34 -161 75 -77 41 -143 75 -147 75 -3 0 -8 4 -10 9 -1 5 -19 18 -38 30
-33 20 -34 21 -10 21 14 0 39 -9 55 -20z m1195 -421 c0 -5 -5 -7 -10 -4 -6 4
-17 1 -25 -5 -12 -10 -15 -9 -15 4 0 10 9 16 25 16 14 0 25 -5 25 -11z m30 1
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M5005 7666 c-20 -15 -15 -46 8 -46 20 0 40 27 33 45 -7 19 -17 19
-41 1z"/>
<path d="M3435 8289 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M3368 8267 c-7 -8 -22 -12 -33 -10 -20 3 -20 3 0 -6 29 -14 33 -13
45 9 12 23 7 26 -12 7z"/>
<path d="M1584 5966 c-3 -8 -1 -23 5 -33 9 -15 10 -13 11 15 0 34 -7 42 -16
18z"/>
<path d="M4571 5304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4891 5304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4581 5214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4793 5040 c1 -11 7 -20 14 -20 17 0 16 8 -1 26 -13 12 -15 12 -13
-6z"/>
<path d="M4482 4980 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5868 5753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5755 5270 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5550 5254 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M5740 5248 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M5266 5137 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M5441 5084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5172 5068 c-14 -14 -16 -28 -3 -28 5 0 11 9 14 20 6 23 5 24 -11 8z"/>
<path d="M3768 4893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
